import { SvgIcon } from "@mui/material";

export function BandaiNamcoLogo() {
  return (
    <SvgIcon sx={{ width: 220, height: 50, mr: 2 }}>
      <svg
        width="220"
        height="50"
        viewBox="0 0 220 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_278_9)">
          <path
            d="M220 5.40801V36.675C220.004 39.6617 217.578 42.0874 214.579 42.0918C214.577 42.0918 214.573 42.0918 214.57 42.0918H18.0114V36.6947H214.59V5.38829H5.4098V29.7353L18.0114 42.3591V50L2.211 34.1726C0.794201 32.7548 1.25107e-06 30.8375 1.25107e-06 28.8369V6.45324C-0.00219875 2.89026 2.8974 0.00219125 6.4746 0H6.4768H214.57C217.569 0 220 2.42133 220 5.40801Z"
            fill="white"
          />
          <path
            d="M106.445 12.2139H102.634V29.6672H106.445V12.2139Z"
            fill="white"
          />
          <path
            d="M42.0375 13.5359C41.4611 12.1554 39.8727 11.5024 38.4867 12.0743C37.8223 12.3482 37.2965 12.8741 37.0193 13.5359L30.3225 29.532H34.4497L36.0491 25.7061H43.0077L44.6071 29.532H48.7343L42.0375 13.5359ZM37.2921 22.7391L39.5273 17.3968L41.7625 22.7391H37.2921Z"
            fill="white"
          />
          <path
            d="M141.588 13.5359C141.011 12.1554 139.423 11.5024 138.037 12.0743C137.372 12.3482 136.847 12.8741 136.569 13.5359L129.873 29.532H133.998L135.599 25.7061H142.56L144.159 29.532H148.28L141.588 13.5359ZM136.84 22.7435L139.075 17.4012L141.31 22.7435H136.84Z"
            fill="white"
          />
          <path
            d="M94.8595 13.5359C94.2831 12.1554 92.6947 11.5024 91.3087 12.0743C90.6443 12.3482 90.1185 12.8741 89.8413 13.5359L83.1445 29.532H87.2717L88.8799 25.717H95.8385L97.4379 29.543H101.565L94.8595 13.5359ZM90.1163 22.7391L92.3493 17.3968L94.5845 22.7391H90.1163Z"
            fill="white"
          />
          <path
            d="M128.995 12.2138V27.4342C128.995 29.8205 125.915 30.7846 124.54 28.8256L117.5 18.7305V29.6671H113.683V14.6044C113.683 12.1546 116.851 11.1663 118.257 13.1757L125.187 23.1021V12.2138H128.995Z"
            fill="white"
          />
          <path
            d="M64.999 12.2138V27.4342C64.999 29.8205 61.919 30.7846 60.544 28.8256L53.504 18.7305V29.6671H49.687V14.6044C49.687 12.1546 52.855 11.1663 54.2608 13.1757L61.1908 23.1021V12.2138H64.999Z"
            fill="white"
          />
          <path
            d="M198 29.9718C191.525 29.9718 188.225 25.62 188.225 21.0688C188.225 15.755 192.731 11.9619 197.875 11.9619C203.463 11.9619 207.632 15.2488 207.632 21.2068C207.625 25.8764 203.962 29.9718 198 29.9718ZM203.478 21.0797C203.5 17.6701 201.3 15.3584 198.251 15.1633C195.294 14.9771 192.467 17.5562 192.46 20.9921C192.46 24.2789 194.88 26.8646 197.96 26.88C201.133 26.8953 203.447 24.4542 203.471 21.0797H203.478Z"
            fill="white"
          />
          <path
            d="M180.75 26.88C177.659 26.8624 175.232 24.2746 175.25 20.9921C175.25 17.5562 178.081 14.9771 181.038 15.1633C182.701 15.2313 184.239 16.0639 185.201 17.4159L187.57 14.2956C185.834 12.7617 183.416 11.9619 180.655 11.9619C175.514 11.9619 171.006 15.755 171.006 21.0688C171.006 25.62 174.306 29.9718 180.783 29.9718C183.557 29.9718 185.83 29.0953 187.471 27.6754L185.154 24.6821C184.145 26.0999 182.495 26.9238 180.75 26.88Z"
            fill="white"
          />
          <path
            d="M168.557 29.6672H164.747V18.6452L160.859 24.395C160.118 25.4906 158.629 25.7799 157.529 25.0436C157.271 24.8705 157.051 24.6514 156.877 24.395L152.99 18.6452V29.6672H149.18V14.5475C149.18 11.7142 152.64 11.3395 154.134 13.5483L158.868 20.5472L163.603 13.5483C165.097 11.3395 168.557 11.7142 168.557 14.5475V29.6672Z"
            fill="white"
          />
          <path
            d="M74.7011 12.2139H69.9865C69.1549 12.2161 68.4795 12.8888 68.4795 13.7171V28.164C68.4795 28.9923 69.1549 29.665 69.9865 29.6672H75.2093C80.7951 29.6672 83.8421 25.368 83.8421 20.8145C83.8421 15.7549 80.2869 12.2139 74.7011 12.2139ZM74.7011 26.6082H72.2811V15.4525H74.7011C77.4951 15.4525 79.7787 17.2735 79.7787 21.0687C79.7787 24.3556 77.2399 26.6082 74.7011 26.6082Z"
            fill="white"
          />
          <path
            d="M29.0003 16.5153C29.0003 14.1246 26.7673 12.2139 23.9733 12.2139H15.7057C14.8719 12.2139 14.1965 12.8866 14.1965 13.7171V28.164C14.1965 28.9945 14.8719 29.6672 15.7057 29.6672H25.4979C28.3953 29.6672 29.9947 27.3642 29.9947 24.9998C29.9947 22.3331 28.0697 21.187 26.1909 20.705C28.3293 19.8087 29.0003 18.6036 29.0003 16.5153ZM22.8139 15.4547C24.3099 15.4547 25.0139 16.1713 25.0139 17.3107C25.0139 18.2902 24.4133 19.4428 22.8139 19.4428H18.0047V15.4525L22.8139 15.4547ZM25.9313 24.4695C25.9313 25.4775 25.3263 26.6082 23.4651 26.6082H18.0047V22.3331H23.5861C25.3681 22.3331 25.9313 23.3608 25.9313 24.4695Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_278_9">
            <rect width="220" height="50" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}
